import { DiceModel } from "./dice.model";

export interface PartyModel {
    code: string;
    creationDate: Date;
    hostPlayerId: string;
    roundNumber: number;
    players: { [key:string]: PartyPlayerModel; };
    round?: RoundModel;
}

export interface PartyPlayerModel {
    name: string;
    initials: string;
    textColour: string;
    backgroundColour: string;
    totalScore: number;
}

export interface PlayerMessage {
    playerId: string,
    playerDetails: PartyPlayerModel
}

export interface RoundModel {
    gridLetters: string;
    revision: number;
    changes: string[];
    created: Date;
    startTime: Date | undefined;
    stage: RoundStageType;
    gameTimeSecs: number;
    players: { [playerId:string]: RoundPlayerModel }
    words: { [word:string]: WordModel; };
    wordTotals: { [score:string]: number}; // score will be 1, 2, 3, 5 or 11
}

export interface RoundPlayerModel {
    playerState: PlayerStateType;
    score: number;
}

export interface WordModel {
    score: number,
    players: string[]
}

export interface PlayerDetails {
    playerId: string;
    name: string;
    avatar: DiceModel;
    score: number;
    total: number;
}

export interface WordDetails {
    word: string;
    score: number;
    playerIds: string[]
}

export interface PartyMessage {
    action: string,
    message: PartyModel | PlayerMessage | PlayerWord[]
}

export interface PlayerWord {
    playerId: string,
    word: string
}

export enum RoundStageType {
    Preparing = 'PREPARING',
    Playing = 'PLAYING',
    Submitting = 'SUBMITTING',
    Tallying = 'TALLYING',
    Complete = 'COMPLETE'
}

export enum PlayerStateType {
    Playing = 'PLAYING',
    Submitted = 'SUBMITTED'
}