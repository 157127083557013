import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { GameController, GameState } from '../../service/game-controller.service';

const CENTRE_REM: number = 2.2;
const SECONDS_HAND_LENGTH_REM: number = 1.6;
const MINUTES_HAND_LENGTH_REM: number = 1.3;

@Component({
  selector: 'app-countdown',
  standalone: true,
  imports: [],
  templateUrl: './countdown.component.html',
  styleUrl: './countdown.component.css'
})
export class CountdownComponent implements OnInit {

  /** Time to initially display on the countdown clock */
  @Input({ alias: 'time-seconds', required: true }) timeSeconds!: number;

  /** If neutered then no event is fired when the timer reaches zero */
  @Input({ alias : 'end-timer' }) endTimer = false;

  /** Signals when the countdown has completed */
  @Output('countdown-finished') countdownFinishedEvent = new EventEmitter<void>();

  /** Time remaining in m:ss format or just ss if there are no minutes */
  formattedTime: string = '';

  /** X position of the end of the minutes hand */
  minutesHandX: string = `${CENTRE_REM}rem`;

  /** Y position of the end of the minutes hand */
  minutesHandY: string = `${CENTRE_REM}rem`;

  /** X position of the end of the seconds hand */
  secondsHandX: string = `${CENTRE_REM}rem`;

  /** Y position of the end of the seconds hand */
  secondsHandY: string = `${CENTRE_REM}rem`;


  constructor(private gameController: GameController) { }

  ngOnInit(): void {
    if (this.timeSeconds < 9999) {
      let mins = Math.floor(this.timeSeconds / 60);
      let secs = Math.floor(this.timeSeconds % 60);
      this.formattedTime = format(mins, secs);
      this.updateMinutesAndSecondsHands(mins, secs);
      this.gameController.stateNotifier.subscribe(gameState => {
        if (gameState === GameState.Play) {
          this.start();
        }
      });
    } else {
      this.formattedTime = '∞';
    }
  }

  /**
   * Starts the countdown timer.  Stops itself when it reaches zero.
   */
  start(): void {
    // This was implemented as an rxjs timer but I encountered a confounding
    // bug where the second time the timer was launched it would ignore the "takeUntil"
    // rule set on it.  Abandoned it in favour of setInterval.
    let count = 0;
    let countdownInterval = setInterval(() => {
      const seconds = this.timeSeconds - count - 1;
      const mins = Math.floor(seconds / 60);
      const secs = Math.floor(seconds % 60);
      this.formattedTime = format(mins, secs);
      this.updateMinutesAndSecondsHands(mins, secs);
      count++;
      if (!seconds) {
        clearInterval(countdownInterval);
        this.countdownFinishedEvent.next();
      }
      if (this.endTimer) {
        clearInterval(countdownInterval);
      }
    }, 1000);
  }

  /**
   * Helper method to update the positions of the minutes and seconds hands
   * 
   * @param mins Minutes remaining
   * @param secs Seconds remaining
   */
  private updateMinutesAndSecondsHands(mins: number, secs: number): void {
    this.minutesHandX = `${calculateHandX(mins, CENTRE_REM, MINUTES_HAND_LENGTH_REM)}rem`;
    this.minutesHandY = `${calculateHandY(mins, CENTRE_REM, MINUTES_HAND_LENGTH_REM)}rem`;
    this.secondsHandX = `${calculateHandX(secs, CENTRE_REM, SECONDS_HAND_LENGTH_REM)}rem`;
    this.secondsHandY = `${calculateHandY(secs, CENTRE_REM, SECONDS_HAND_LENGTH_REM)}rem`;
  }
}

/**
 * Expresses minutes and seconds in "m:ss" format or just "ss" if there are no minutes
 * 
 * @param mins Minutes remaining
 * @param secs Seconds remaining
 * @returns Formated time remaining
 */
function format(mins: number, secs: number): string {
  if (mins) {
    let leadingZero = secs < 10 ? '0' : '';
    return `${mins}:${leadingZero}${secs}`;
  }
  return `${secs}`;
}

/**
 * Calculate the X coordinate of the end of a clock hand.
 * 
 * @param minsOrSecs time in whatever unit
 * @param centerX centre X coordinate of the hand
 * @param handLength length of the hand in whatever unit
 * @returns the X coordinate of the end of a clock hand.
 */
function calculateHandX(minsOrSecs: number, centerX: number, handLength: number): number {
  let sin = Math.sin(Math.PI * (360 * (60 - minsOrSecs) / 60) / 180);
  let handX = centerX + (handLength * sin);
  return Math.round(handX * 10) / 10;
}

/**
 * Calculate the X coordinate of the end of a clock hand.
 * 
 * @param minsOrSecs time in whatever unit
 * @param centerY centre Y coordinate of the hand
 * @param handLength length of the hand in whatever unit
 * @returns the Y coordinate of the end of a clock hand.
 */
function calculateHandY(minsOrSecs: number, centerY: number, handLength: number): number {
  let cos = Math.cos(Math.PI * (360 * (60 - minsOrSecs) / 60) / 180);
  let handY = centerY - (handLength * cos);
  return Math.round(handY * 10) / 10;
}
