<div [class.x-small]="gridProps.diceProps.size === DiceSize.XSmall"
     [class.small]="gridProps.diceProps.size === DiceSize.Small"
     [class.medium]="gridProps.diceProps.size === DiceSize.Medium"
     (touchmove)="preventScroll($event)"
     >
    <table (touchstart)="onSwipeStart()" (touchmove)="onTouchMove($event)" (touchend)="onSwipeEnd()"
           (mousedown)="onMouseDown($event)" (mousemove)="onMouseMove($event)" (mouseup)="onMouseUp($event)">
        <tr *ngFor="let gridRow of grid">
            <td *ngFor="let dice of gridRow">
                <app-dice #diceCmp [dice]="dice" (click)="onDiceClick(diceCmp)" (collision)="onCollision($event)">
                </app-dice>
            </td>
        </tr>
    </table>
</div>