import { EventEmitter, Injectable } from '@angular/core';
import { Subject } from 'rxjs';

export enum AlertLevel {
  Info,
  Warning,
  Error
}

export interface Alert {
  message: string;
  level: AlertLevel;
}

@Injectable({
  providedIn: 'root'
})
export class AlertService {

  alerts: Alert[] = [];
  timeout: number | undefined;

  constructor() { }

  public newAlert(alert: Alert) {
    this.alerts.push(alert);
    if (!this.timeout) {
      this.timeout = this.doTimeout();
    }
  }

  public closeAlert() {
    window.clearTimeout(this.timeout);
    this.timeout = undefined;
    this.closeAndDoTimeout();
  }

  public clear() {
    window.clearTimeout(this.timeout);
    this.timeout = undefined;
    this.alerts = [];
  }

  private doTimeout(): number {
    return window.setTimeout(() => {
      this.timeout = undefined;
      this.closeAndDoTimeout();
    }, 5000);
  }

  private closeAndDoTimeout() {
    this.alerts.pop();
    if (this.alerts.length) {
      this.timeout = this.doTimeout();
    }
  }
}
