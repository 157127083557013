import { Injectable, OnDestroy } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { catchError, delay, Observable, of, retry, Subject, tap, throwError } from 'rxjs';
import { PartyMessage, PartyModel, PartyPlayerModel } from '../model/party.model';
import { ConfigService } from './config.service';
import { environment } from '../../environments/environment';
import { AlertLevel, AlertService } from './alert.service';

const PARTY_URL = `https://${environment.apiDomain}/${environment.apiBasePath}/party`;
const WEB_SOCKET_URL = `wss://${environment.webSocketDomain}/${environment.apiBasePath}`;
const WEB_SOCKET_RECONNECT_DELAY_MS = 5000;
const HEARTBEAT_PERIOD_MS = 5000;

@Injectable({
  providedIn: 'root'
})
export class PartyService implements OnDestroy {

  private webSocket: WebSocket | undefined;
  private partyCode = '';
  private playerId = '';
  private playerDetails: PartyPlayerModel | undefined;
  private partyMessageSubject = new Subject<PartyMessage>();
  private keepWebsocketAlive = true;

  public partyMessageNotifier = this.partyMessageSubject.asObservable();

  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };

  constructor(private http: HttpClient, private config: ConfigService, private alertService: AlertService) {
    this.connectWebSocket();
    // Need ping pong!
    // https://stackoverflow.com/questions/26971026/handling-connection-loss-with-websockets
    // Also need to clear party code on exit
    const interval = setInterval(() => {
      console.log(this.keepWebsocketAlive ? 'Sending heartbeat' : 'Stopping heartbeat');
      if (this.keepWebsocketAlive) {
        if (this.webSocket?.readyState === WebSocket.OPEN) {
          this.webSocket!.send('heartbeat');
        }
      } else {
        clearInterval(interval);
      }
    }, HEARTBEAT_PERIOD_MS);
  }

  createParty(): Observable<PartyModel> {
    const payload = { 
      eventType: "CREATE_PARTY", 
      message: { 
        player: this.config.playerDetails, 
        gameTimeSecs: this.config.gameTimeSecs
      }
    };
    return this.http.post<PartyModel>(PARTY_URL, JSON.stringify(payload), this.httpOptions)
      .pipe(
        tap(party => {
          this.partyCode = party.code;
          this.playerId = party.hostPlayerId;
          this.playerDetails = party.players[party.hostPlayerId];
          this.subscribeToPartyMessages();
        }),
        retry({ count: 2, delay: 1000 }),
        catchError(error => this.handleError(error, 'creating party'))
      );
  }

  joinParty(partyCode: string): Observable<JoinPartyResponse> {
    const payload = { eventType: "JOIN_PARTY", partyCode, player: this.config.playerDetails };
    return this.http.post<JoinPartyResponse>(PARTY_URL, JSON.stringify(payload), this.httpOptions)
      .pipe(
        tap(joinPartyResponse => {
          this.partyCode = joinPartyResponse.party.code;
          this.playerId = joinPartyResponse.playerId;
          this.playerDetails = joinPartyResponse.party.players[joinPartyResponse.playerId];
          this.subscribeToPartyMessages();
        }),
        retry({ count: 2, delay: 1000 }),
        catchError(error => this.handleError(error, 'joining party'))
      );
  }

  startRound(partyCode: string): Observable<PartyModel> {
    const payload = {
      eventType: "START_ROUND",
      message: {
        gameTimeSecs: this.config.gameTimeSecs
      }
    };
    return this.http.patch<PartyModel>(`${PARTY_URL}/${partyCode}`, JSON.stringify(payload), this.httpOptions)
      .pipe(
        retry({ count: 2, delay: 1000 }),
        catchError(error => this.handleError(error, 'starting round'))
      );
  }

  getPlayersAndRound(partyCode: string): Observable<PartyModel> {
    return this._get(partyCode, 'PLAYERS_AND_ROUND');
  }

  getPlayers(partyCode: string): Observable<PartyModel> {
    return this._get(partyCode, 'PLAYERS');
  }

  addWord(partyCode: string, word: string, playerId: string) {
    const payload = { word, playerId, time: new Date().toISOString()};
    return this.http.post<any>(`${PARTY_URL}/${partyCode}/word`, JSON.stringify(payload), this.httpOptions);
  }

  submitWords(partyCode: string, words: string[], playerId: string) {
    const payload = { words, playerId, time: new Date().toISOString()};
    return this.http.post<any>(`${PARTY_URL}/${partyCode}/words`, JSON.stringify(payload), this.httpOptions)
    .pipe(
      retry({ count: 3, delay: 1000 }),
      catchError(error => this.handleError(error, 'submitting words'))
    );
  }

  getRoundResults(partyCode: string): Observable<PartyModel> {
    return this._get(partyCode, 'ROUND_RESULTS');
  }

  ngOnDestroy(): void {
    this.keepWebsocketAlive = false;
    this.webSocket!.close();
  }

  private connectWebSocket() {
    console.log(`Connecting to WebSocket at ${WEB_SOCKET_URL}`);
    this.webSocket = new WebSocket(WEB_SOCKET_URL);

    this.webSocket.addEventListener('open', () => {
      console.log(`WebSocket open at ${new Date()}`);
      if (this.partyCode) {
        this.subscribeToPartyMessages();
      }
    });

    this.webSocket.addEventListener('error', () => 
      this.alertService.newAlert({ message: 'Websocket error', level: AlertLevel.Error }));
    
    this.webSocket.addEventListener('close', () => {
      console.log(`WebSocket closed at ${new Date()}. Open new websocket? ${this.keepWebsocketAlive}`);
      if (this.keepWebsocketAlive) {
        this.alertService.newAlert({ message: 'Reconnecting websocket', level: AlertLevel.Info });
        setTimeout(() => this.connectWebSocket(), WEB_SOCKET_RECONNECT_DELAY_MS);
      }
    });
    
    this.webSocket.addEventListener('message', (event) => {
      const partyMessage = JSON.parse((event as MessageEvent).data);
      this.partyMessageSubject.next(partyMessage);
    });
  }

  private subscribeToPartyMessages() {
    if (this.webSocket?.readyState === WebSocket.OPEN) {
      this.webSocket!.send(JSON.stringify({ 
        action: 'joinparty', 
        message: {
          partyCode: this.partyCode, 
          playerId: this.playerId, 
          playerDetails: this.playerDetails 
        }
      }));
    } else {
      setTimeout(() => this.subscribeToPartyMessages(), WEB_SOCKET_RECONNECT_DELAY_MS);
    }
  }

  private _get(partyCode: string, details: string): Observable<PartyModel> {
    const params = new HttpParams().set('details', details);
    const url = `${PARTY_URL}/${partyCode}`;
    let context;
    switch (details) {
      case 'PLAYERS_AND_ROUND': context = 'players and round'; break;
      case 'PLAYERS': context = 'players'; break;
      case 'ROUND_RESULTS': context = 'round results'; break;
      default: context = 'data';
    }
    return this.http.get<PartyModel>(url, { params })
      .pipe(
        retry({ count: 3, delay: 1000 }),
        catchError(error => this.handleError(error, context))
      );
  }

  private handleError(error: any, doingWhat: string): Observable<never> {
    if (error.status === 0) {
      this.alertService.newAlert({ message: `Network error ${doingWhat}`, level: AlertLevel.Error });
    } else {
      this.alertService.newAlert( { message: `Server error ${doingWhat}`, level: AlertLevel.Error });
    }
    return throwError(() => error);
  }
}

export interface JoinPartyResponse {
  playerId: string,
  party: PartyModel
}
