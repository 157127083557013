<div class="container">
    <app-grid (word-change)="onGridWordChange($event)" (word-submit)="onGridWordSubmit($event)" [disabled]="readOnly"></app-grid>
    <div class="word-and-timer">
        <div class="word">
            <button [disabled]="isWordFieldDisabled()" (click)="onWordClicked()">{{word}}</button>
        </div>
        <div class="timer">
            <app-countdown [time-seconds]="timeRemainingSecs" [end-timer]="endTimer" (countdown-finished)="onCountdownFinished()" 
                           (click)="showQuitConfirmation = !showQuitConfirmation"></app-countdown>
        </div>
    </div>
    @if (showQuitConfirmation) {
        <div class="quit-confirmation">
            <span>Quit the round? </span>
            <div>
                <button type="button" id="quit-round" (click)="quitRound()" [disabled]="submitButtonsDisabled">Quit</button>
                <button type="button" id="cancel-quit" (click)="showQuitConfirmation = false">Cancel</button>
            </div>
        </div>
    }
    <app-word-list></app-word-list>
</div>
