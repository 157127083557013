import { Component, OnInit, ViewChild } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { GameController } from '../service/game-controller.service';
import { ConfigService, PlayerDetails, Colour } from '../service/config.service';
import { DiceComponent } from '../common/dice/dice.component';
import { DiceModel } from '../model/dice.model';
import { BoggleTextComponent } from '../common/boggle-text/boggle-text.component';
import { DiceTextComponent } from '../common/dice-text/dice-text.component';
import { RoundOptions, RoundOptionsComponent } from '../common/round-options/round-options.component';
import { ReadySetGoComponent, ReadySetGoState } from '../common/ready-set-go/ready-set-go.component';
import { AlertService, AlertLevel } from '../service/alert.service';

enum Section {
  MainMenu,
  StartPartyOptions,
  StartParty,
  JoinPartyOptions,
  JoinParty,
  Solver,
  About
}

@Component({
  selector: 'app-title-screen',
  standalone: true,
  imports: [CommonModule, FormsModule, DiceComponent, BoggleTextComponent, DiceTextComponent, RoundOptionsComponent, ReadySetGoComponent],
  templateUrl: './title-screen.component.html',
  styleUrl: './title-screen.component.css'
})
export class TitleScreenComponent implements OnInit {

  @ViewChild(ReadySetGoComponent) readySetGoComponent!: ReadySetGoComponent;

  shownSection = Section.MainMenu;
  submitButtonsDisabled = false;
  partyCode = '';
  playerName = '';
  playerInitials = '';
  playerDice = DiceModel.forText(' ');
  partyPlayers: NameAndAvatar[] = [];
  hostPlayerName = '';
  roundOptions: RoundOptions = { gameLength: 60 };
  roundEditing = false;
  roundReady = false;
  roundNumber: number | undefined;
  showingReadySetGo = false;
  readySetGoText = '';

  sectionType = Section;

  constructor(private gameController: GameController, private config: ConfigService, private alertService: AlertService) { }

  ngOnInit(): void {
    this.roundOptions.gameLength = this.config.gameTimeSecs;
    if (this.config.playerDetails) {
      this.playerName = this.config.playerDetails.name;
      this.playerInitials = this.config.playerDetails.initials;
      this.playerDice.letter = this.playerInitials[0] || ' ';
      this.playerDice.secondLetter = this.playerInitials[1];
    }

    this.gameController.playersNotifier.subscribe(players => {
      this.partyPlayers = players.map<NameAndAvatar>(player => { 
        return { 
          name: player.name, 
          avatar: DiceModel.forText(player.initials[0], player.initials[1])
        };
      });
    });

    this.gameController.roundReadyNotifier.subscribe(roundReady => {
      this.roundReady = roundReady
      this.submitButtonsDisabled = false;
    });

    this.gameController.roundStartingNotifier.subscribe(startTime => {
      this.roundNumber = this.gameController.roundNumber;
      this.readySetGoComponent.readySetGoBy(startTime);
    });
  }

  joinParty() {
    this.shownSection = Section.JoinPartyOptions;
  }

  solve() {
    console.log("Solve me this");
    this.alertService.newAlert({ message: "Solver isn't implemented yet!", level: AlertLevel.Error });
  }

  showAbout() {
    console.log("Tell me about it");
    this.alertService.newAlert({ message: "About isn't implemented yet!", level: AlertLevel.Error });
  }

  updateInitials() {
    const nameParts = this.playerName.trim().split(/\s+/);
    if (!nameParts || !nameParts.length) {
      this.playerInitials = '';
      return;
    }

    let initials = '';
    let part = nameParts[0];
    initials += part.charAt(0).toUpperCase();
    if (nameParts.length === 1) {
      if (part.length > 1) {
        initials += part.charAt(part.length - 1).toLowerCase();
      }
    } else {
      part = nameParts[nameParts.length - 1];
      initials += part.charAt(0).toLowerCase();
    }
    this.playerInitials = initials;
    this.playerDice.letter = initials[0] || ' ';
    this.playerDice.secondLetter = initials[1];
  }

  setRoundEditing(isEditing: boolean) {
    this.roundEditing = isEditing;
  }

  submitPartyOptions() {
    let playerDetails: PlayerDetails = {
      name: this.playerName,
      initials: this.playerInitials,
      textColour: Colour.Black,
      backgroundColour: Colour.White
    };
    this.config.playerDetails = playerDetails;
    this.partyPlayers.push({name: playerDetails.name, avatar: DiceModel.forText(playerDetails.initials[0], playerDetails.initials[1])})
    if (this.shownSection === Section.StartPartyOptions) {
      this.config.gameTimeSecs = this.roundOptions.gameLength;
      this._startParty();
    } else {
      this.partyCode = this.partyCode.toUpperCase();
      this._joinParty();
    }
  }

  startRound() {
    this.submitButtonsDisabled = true;
    this.gameController.startRound();
  }

  readySetGoChange(state: ReadySetGoState) {
    if (state === ReadySetGoState.Ready) {
      this.showingReadySetGo = true;
    } else if (state === ReadySetGoState.Complete) {
      this.gameController.nextGameState();
    }
  }

  gotoMainMenu() {
    this.submitButtonsDisabled = false;
    this.roundReady = false;
    this.shownSection = Section.MainMenu;
  }

  private _startParty() {
    this.submitButtonsDisabled = true;
    this.gameController.startParty().subscribe(partyCode => {
      this.partyCode = partyCode;
      this.hostPlayerName = this.gameController.hostPlayerName;
      this.shownSection = Section.StartParty;
    });
  }

  private _joinParty() {
    this.submitButtonsDisabled = true;
    this.gameController.joinParty(this.partyCode).subscribe(() => {
      this.hostPlayerName = this.gameController.hostPlayerName;
      this.shownSection = Section.JoinParty;
    });
  }
}

interface NameAndAvatar {
  name: string;
  avatar: DiceModel;
}
