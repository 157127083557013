import { Component, OnInit, ViewChild } from '@angular/core';
import { GridComponent } from '../common/grid/grid.component';
import { CountdownComponent } from './countdown/countdown.component';
import { ConfigService } from '../service/config.service';
import { WordListComponent } from '../common/word-list/word-list.component';
import { GameController, AddWordResult } from '../service/game-controller.service';
import { DiceState } from '../model/dice.model';

const WORD_BUTTON_HELP_TEXT = 'Tap dice to build word';

@Component({
  selector: 'app-play-screen',
  standalone: true,
  imports: [GridComponent, CountdownComponent, WordListComponent],
  templateUrl: './play-screen.component.html',
  styleUrl: './play-screen.component.css'
})
export class PlayScreenComponent implements OnInit {
  word: string = WORD_BUTTON_HELP_TEXT;
  timeRemainingSecs!: number;
  readOnly = false;
  showQuitConfirmation = false;
  endTimer = false;
  submitButtonsDisabled = false;
  
  @ViewChild(GridComponent) gridCmp!: GridComponent;
  @ViewChild(WordListComponent) wordListCmp!: WordListComponent;

  constructor(private config: ConfigService, private gameController: GameController) { }

  ngOnInit(): void {
    this.timeRemainingSecs = this.gameController.gameTimeSecs;
    this.gameController.wordSubmittedNotifier.subscribe(playerWord => this.wordListCmp.addPlayerToWord(playerWord.playerId, playerWord.word));
  }

  /**
   * Determines whether the word field should be disabled.  This is when
   * it's showing the help text or if the word entered doesn't have enough
   * characters yet.
   * 
   * @returns whether the word should be disabled. 
   */
  isWordFieldDisabled(): boolean {
    return this.word === WORD_BUTTON_HELP_TEXT || 
      this.word.length < this.config.minWordLength ||
      this.readOnly;
  }

  /**
   * Submits the selected word when the word field is tapped.
   */
  onWordClicked(): void {
    if (!this.isWordFieldDisabled()) {
      this.onGridWordSubmit(this.word);
    }
  }

  /**
   * Updates the word shown in the word field.
   * 
   * @param newWord the new word to set in the word field.
   */
  onGridWordChange(newWord: string): void {
    this.word = newWord;
  }

  /**
   * Submits the selected word if the user is submitting the word via the grid.
   * Clears the selected dice which in turn clears the word field.
   * 
   * @param word the word to submit
   */
  onGridWordSubmit(word: string): void {
    if (!this.readOnly) {
      const result = this.gameController.addWord(word);
      switch (result) {
        case AddWordResult.Correct:
          this.wordListCmp.addWord(this.word);
          this.gridCmp.clearSelectedDice(DiceState.Correct);
          break;
        case AddWordResult.Incorrect:
          this.gridCmp.clearSelectedDice(DiceState.Incorrect);
          break;
        case AddWordResult.Duplicate:
          this.gridCmp.clearSelectedDice(DiceState.Inactive);
          break;
        default:
          throw new Error(`Unknown AddWordResult encountered: ${result}`);
      }
    }
  }

  quitRound(): void {
    this.submitButtonsDisabled = true;
    this.endTimer = true;
    this.onCountdownFinished();
  }

  onCountdownFinished(): void {
    this.onWordClicked();
    this.readOnly = true;
    this.gameController.playComplete();
  }
}
