import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

export enum GameLength {
  OneMin = 60,
  ThreeMin = 180,
  Infinite = 9999
}

export interface RoundOptions {
  gameLength: GameLength
}

@Component({
  selector: 'app-round-options',
  standalone: true,
  imports: [],
  templateUrl: './round-options.component.html',
  styleUrl: './round-options.component.css'
})
export class RoundOptionsComponent implements OnInit {
  @Input() options: RoundOptions = { gameLength: GameLength.OneMin };
  @Output() optionsChange = new EventEmitter<RoundOptions>();

  @Output() editing = new EventEmitter<boolean>();

  gameLengthText = "1 min";
  gameLengthOptions: GameLengthOption[] = [
    { text: "1 min", gameLength: GameLength.OneMin }, 
    { text: "3 min", gameLength: GameLength.ThreeMin },
    { text: "Infinite", gameLength: GameLength.Infinite }
  ];
  gameLengthEditing = false;

  ngOnInit(): void {
    this.gameLengthText = this.gameLengthOptions.filter(option => option.gameLength === this.options.gameLength)[0].text;
  }

  editGameLength() {
    this.gameLengthEditing = true;
    this.editing.next(this.isEditing());
  }

  setGameLength(gameLengthOption: GameLengthOption) {
    this.gameLengthText = gameLengthOption.text;
    this.options.gameLength = gameLengthOption.gameLength;
    this.optionsChange.emit(this.options);
    this.gameLengthEditing = false;
    this.editing.next(this.isEditing());
  }

  isEditing() {
    return this.gameLengthEditing;
  }
}

interface GameLengthOption {
  text: string;
  gameLength: GameLength
}
