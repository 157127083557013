import { Component, OnInit } from '@angular/core';
import { PlayScreenComponent } from './play-screen/play-screen.component';
import { TitleScreenComponent } from './title-screen/title-screen.component';
import { ResultsScreenComponent } from './results-screen/results-screen.component';
import { GameController, GameState } from './service/game-controller.service';
import { AlertComponent } from './alert/alert.component';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [PlayScreenComponent, TitleScreenComponent, ResultsScreenComponent, AlertComponent],
  templateUrl: './app.component.html',
  styleUrl: './app.component.css'
})
export class AppComponent implements OnInit {

  /** Controls which screen is displayed in the game */
  gameState = GameState.Title;

  /** Exposes the enum to the template */
  GameState = GameState;

  constructor(private gameController: GameController) { }

  ngOnInit(): void {
    this.gameController.stateNotifier.subscribe(gameState => this.changeState(gameState));
  }

  private changeState(gameState: GameState) {
    this.gameState = gameState;
  }
}
