import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { PartyService } from './party.service';
import { RoundStageType, PartyModel, PartyPlayerModel, PlayerDetails, WordDetails, WordModel, PartyMessage, PlayerMessage, PlayerWord } from '../model/party.model';
import { ConfigService } from './config.service';
import { DiceModel } from '../model/dice.model';

export enum GameState {
  Title,
  Play,
  Results
}

export enum AddWordResult {
  Correct,
  Incorrect,
  Duplicate
}

@Injectable({
  providedIn: 'root'
})
export class GameController {

  private _party: PartyModel | undefined;
  private _playerId = '';
  private _addedWords: string[] = [];
  private _invalidWords: string[] = [];

  private _currentState = new BehaviorSubject<GameState>(GameState.Title);
  private _playersUpdated = new BehaviorSubject<PartyPlayerModel[]>([]);
  private _roundReady = new BehaviorSubject<boolean>(false);
  private _roundStarting = new Subject<Date>();
  private _wordSubmitted = new Subject<PlayerWord>();

  public stateNotifier = this._currentState.asObservable();
  public playersNotifier = this._playersUpdated.asObservable();
  public roundReadyNotifier = this._roundReady.asObservable();
  public roundStartingNotifier = this._roundStarting.asObservable();
  public wordSubmittedNotifier = this._wordSubmitted.asObservable();

  get partyCode(): string | undefined {
    return this._party?.code;
  }

  get playerId(): string {
    return this._playerId;
  }

  get hostPlayerId(): string {
    return this._party!.hostPlayerId;
  }

  get hostPlayerName(): string {
    return this._party!.players[this._party!.hostPlayerId].name;
  }

  get amIHost(): boolean {
    return this.playerId === this.hostPlayerId;
  }

  get roundNumber(): number | undefined {
    return this._party?.roundNumber;
  }

  get gridLetters(): string | undefined {
    return this._party?.round?.gridLetters || 'utbiewolnefridwr';
  }

  get gameTimeSecs(): number {
    return this._party?.round?.gameTimeSecs || this.config.gameTimeSecs;
  }

  get playerDetailsList(): PlayerDetails[] {
    if (!this._party || !this._party.round) {
      return [];
    }
    const playersMap = this._party.players;

    return Object.entries(this._party.round.players).map(([playerId, roundPlayerModel]) => {
      const player = playersMap[playerId];
      return {
        playerId,
        name: player.name,
        avatar: DiceModel.forText(player.initials.charAt(0), player.initials.charAt(1)),
        score: roundPlayerModel.score,
        total: player.totalScore
      }
    });
  }

  get playerAvatarsMap(): {[playerId: string]: DiceModel} {
    if (!this._party || !this._party.round) {
      return {};
    }

    const playerAvatars: {[playerId: string]: DiceModel} = {};
    for (let [playerId, player] of Object.entries(this._party.players)) {
      playerAvatars[playerId] = DiceModel.forText(player.initials.charAt(0), player.initials.charAt(1));
    }
    return playerAvatars;
  }

  get wordDetailsMap(): { [word:string]: WordModel } {
    if (!this._party || !this._party.round) {
      return {};
    }
    return this._party.round.words;
  }

  get wordDetailsList(): WordDetails[] {
    if (!this._party || !this._party.round) {
      return [];
    }

    const wordDetailsList = wordsMapToArray(this._party.round.words);
    return wordDetailsList.sort((a, b) => a.word.localeCompare(b.word));
  }

  get wordTotals(): {[scorePoints: number]: number} {
    if (!this._party || !this._party.round) {
      return {};
    }
    const numberKeyed: {[scorePoints: number]: number} = {};
    for (let [scorePoints, count] of Object.entries(this._party.round.wordTotals)) {
      numberKeyed[Number.parseInt(scorePoints)] = count;
    }
    return numberKeyed;
  }

  get invalidWords(): string[] {
    return this._invalidWords;
  }

  constructor(private partyService: PartyService, private config: ConfigService) {
    partyService.partyMessageNotifier.subscribe(event => this.onNewPartyMessage(event));
  }

  public nextGameState(gameState?: GameState): void {
    if (gameState !== undefined && gameState !== null) {
      this._currentState.next(gameState);
    } else {
      this._currentState.next((this._currentState.getValue() + 1) % 4);
    }
  }

  public startParty(): Observable<string> {
    return this.partyService.createParty()
      .pipe(
        tap(party => {
          this._party = party;
          this._playerId = party.hostPlayerId;
          this._playersUpdated.next(this.playersMapToList());
        }),
        map(party => party.code)
      );
  }

  public joinParty(partyCode: string): Observable<void> {
    return this.partyService.joinParty(partyCode)
      .pipe(
        tap(joinPartyResponse => {
          this._party = joinPartyResponse.party;
          this._playerId = joinPartyResponse.playerId;
          this._playersUpdated.next(this.playersMapToList());
        }),
        map(() => undefined)
      );
  }

  public startRound() {
    this.partyService.startRound(this.partyCode!).subscribe();
  }

  public addWord(word: string): AddWordResult {
    if (!word || word.length < this.config.minWordLength) {
      return AddWordResult.Incorrect;
    }
    if (!this.isValidWord(word)) {
      this._invalidWords.push(word);
      return AddWordResult.Incorrect;
    }
    if (this._addedWords.indexOf(word) >= 0) {
      return AddWordResult.Duplicate;
    }
    this.partyService.addWord(this.partyCode!, word, this.playerId).subscribe();
    this._addedWords.push(word);
    return AddWordResult.Correct;
  }

  public playComplete(): void {
    this.partyService.submitWords(this.partyCode!, this._addedWords, this.playerId).subscribe();
  }

  private onNewPartyMessage(partyMessage: PartyMessage) {
    switch (partyMessage.action) {
      case 'roundcreated':
        this.onRoundCreated(partyMessage.message as PartyModel);
        break;
      case 'playerjoined':
        this.onPlayerJoined(partyMessage.message as PlayerMessage);
        break;
      case 'roundstarting':
        console.log(`Received roundstarting: ${partyMessage.message}`);
        this.onRoundStarting(partyMessage.message as PartyModel);
        break;
      case 'wordsubmitted':
        this.onWordSubmitted(partyMessage.message as PlayerWord[]);
        break;
      case 'resultsin':
        this.onResultsIn(partyMessage.message as PartyModel);
        break;
      default:
        console.error(`Unexpected PartyMessage action encountered: ${partyMessage.action}`);
    }
  }

  private onRoundCreated(party: PartyModel) {
    this._party!.round = party.round;
    this._roundReady.next(true);
    this._party!.players = party.players;
    this._playersUpdated.next(this.playersMapToList());
  }

  private onPlayerJoined(playerMessage: PlayerMessage) {
    this._party!.players[playerMessage.playerId] = playerMessage.playerDetails;
    this._playersUpdated.next(this.playersMapToList());
  }

  private onRoundStarting(party: PartyModel) {
    this._addedWords.length = 0;
    this._invalidWords.length = 0;
    this._party = party; // Set the party again, to ensure it's up-to-date
    this._roundStarting.next(new Date(party.round!.startTime!));
  }

  private onWordSubmitted(playerWords: PlayerWord[]) {
    console.log(`Player words: ${JSON.stringify(playerWords)}`);
    for (let playerWord of playerWords) {
      const players = this._party!.round!.words[playerWord.word].players;
      if (players.indexOf(playerWord.playerId) < 0) {
        players.push(playerWord.playerId);
      }
      this._wordSubmitted.next(playerWord);
    }
  }

  private onResultsIn(party: PartyModel) {
    console.log(`Results tallied. Round stage: ${party.round?.stage}`);
    if (party.round?.stage === RoundStageType.Complete) {
      this._party = party;
      this._currentState.next(GameState.Results);
      console.log(`We have the results! ${JSON.stringify(party)}`);
    }
  }

  private playersMapToList(): PartyPlayerModel[] {
    const playersMap = this._party?.players;
    const players: PartyPlayerModel[] = [];
    if (!playersMap) {
      return players;
    }
    for (let player of Object.values(playersMap)) {
      players.push(player);
    }
    return players;
  }

  private isValidWord(word: string): boolean {
    return !!this._party!.round!.words[word];
  }
}

function wordsMapToArray(wordsMap: {[word:string]: WordModel}): WordDetails[] {
  const wordDetailArray: WordDetails[] = [];
  for (let [word, wordModel] of Object.entries(wordsMap)) {
    wordDetailArray.push({
      word,
      score: wordModel.score,
      playerIds: wordModel.players
    })
  }
  return wordDetailArray;
}
