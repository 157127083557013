<div class="container">
    <div class="content" [class.overlay-darken]="showingReadySetGo">
        <div class="grid-and-scores">
            <app-grid [disabled]="true" [gridProps]="gridProps"></app-grid>
            @if (players.length === 1) {
                <div>
                    <h3>Round {{roundNum}} Score</h3>
                    <p>{{players[0].score}}</p>
                    <h3>Total Score</h3>
                    <p>{{players[0].total}}</p>
                </div>
            } @else {
                <div class="rank-container">
                    <div class="filters">
                        <div>
                            <input type="radio" name="rank-type" id="round-score" value="round-score" (click)="rankScores(RankType.RoundScore)" checked>
                            <label for="round-score">Round {{roundNum}}</label>
                            <input type="radio" name="rank-type" id="total-score" value="total-score" (click)="rankScores(RankType.TotalScore)">
                            <label for="total-score">Total</label>
                        </div>
                    </div>
                    <div class="rankings">
                        @for (player of players; track player.name; let rank = $index) {
                            <div class="rank-num">{{rank + 1}}{{rank < 3 ? rankSuffix[rank] : 'th'}}</div>
                            <div class="rank-name">
                                <app-dice [dice]="player.avatar"></app-dice>
                                <div>&nbsp;{{player.name}}</div>
                            </div>
                            <div>{{rankType === RankType.RoundScore ? player.score : player.total }}</div>
                        }
                    </div>
                </div>
            }
        </div>

        <div class="view-pills">
            <div [class.selected-view]="selectedView === ViewType.Results"
                (click)="selectedView = ViewType.Results">Results</div>
            <div [class.selected-view]="selectedView === ViewType.NextRound"
                (click)="selectedView = ViewType.NextRound">Next Round</div>
            <div [class.selected-exit]="selectedView === ViewType.Exit"
                (click)="selectedView = ViewType.Exit">Exit</div>
        </div>

        @if (selectedView === ViewType.Results) {
            <div class="scrollable">
                <app-word-list [word-list]="wordDetailsList" [invalid-words]="invalidWordList" (word-select)="selectWord($event)"></app-word-list>
            </div>
        }
        @if (selectedView === ViewType.NextRound) {
            <div class="next-round">
                <div>Round {{roundNum + 1}}</div>
                @if (amIHost) {
                    <app-round-options [(options)]="nextRoundOptions" (editing)="nextRoundEditing = $event"></app-round-options>
                    <button type="button" [disabled]="submitButtonsDisabled || nextRoundEditing" (click)="startRound()">Start Round!</button>
                } @else {
                    <div>Waiting on {{hostPlayerName}} to start the next round...</div>
                }
            </div>
        }
        @if (selectedView === ViewType.Exit) {
            <div class="quit-game">
                <div>Are you sure you want to quit?</div>
                <button type="button" [disabled]="submitButtonsDisabled" (click)="quit()">Quit Game</button>
            </div>
        }
    </div>
    <app-ready-set-go [round-number]="roundNum" (state-change)="readySetGoChange($event)"></app-ready-set-go>
</div>