import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BoggleTextComponent } from '../boggle-text/boggle-text.component';
import { DiceTextComponent } from '../dice-text/dice-text.component';
import { take, timer } from 'rxjs';

export enum ReadySetGoState {
  Ready,
  Set,
  Go,
  Complete
}

@Component({
  selector: 'app-ready-set-go',
  standalone: true,
  imports: [BoggleTextComponent, DiceTextComponent],
  templateUrl: './ready-set-go.component.html',
  styleUrl: './ready-set-go.component.css'
})
export class ReadySetGoComponent {

  /** The round number to display */
  @Input({ alias: 'round-number' }) roundNumber = 1;

  /** Communicates the Ready, Set, Go and Complete states */
  @Output('state-change') stateChangeEmitter = new EventEmitter<ReadySetGoState>();

  /** Component stays hidden until true */
  showReadySetGo = false;

  /** The text to display below the round number */
  readySetGoText = '';

  readySetGoBy(endTime: Date) {
    const startCountdownMs = endTime.getTime() - Date.now() - 4000;
    const readySetGoTextValues = ['READY', 'SET', 'GO'];
    timer(startCountdownMs, 1000)
      .pipe( take(4) )
      .subscribe({
          next: (callNumber) => {
            console.log('Ready set go call number: ' + callNumber);
            if (callNumber === ReadySetGoState.Ready) {
              this.showReadySetGo = true;
            }
            if (callNumber < readySetGoTextValues.length) {
              this.readySetGoText = readySetGoTextValues[callNumber];
            }
            this.stateChangeEmitter.next(callNumber);
          },
          error: (error) => console.log(`Oh no! An error in Ready Set Go! ${JSON.stringify(error)}`)
      });
  }
}
