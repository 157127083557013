import { CommonModule, NgFor } from '@angular/common';
import { Component, EventEmitter, Input, Output, QueryList, ViewChildren } from '@angular/core';
import { BoundsType, DiceComponent } from '../dice/dice.component';
import { DiceModel, DiceState, DiceProps, DiceSize, DiceOrientation } from '../../model/dice.model';
import { ConfigService } from '../../service/config.service';
import { GameController } from '../../service/game-controller.service';
import { map, take, timer } from 'rxjs';

export interface GridProps {
  diceProps: DiceProps
}

const defaultGridProps: GridProps = {
  diceProps: {
    size: DiceSize.Large,
    deviationDegrees: 4,
    orientation: DiceOrientation.Random
  }
};

@Component({
  selector: 'app-grid',
  standalone: true,
  imports: [CommonModule, DiceComponent],
  templateUrl: './grid.component.html',
  styleUrl: './grid.component.css'
})
export class GridComponent {

  /** Sets whether user inputs are accepted */
  @Input() disabled = false;

  /** Properties defining the look and size of the grid and dice */
  @Input() gridProps = defaultGridProps;

  /**
   * Called when letters are added or removed from the word and sends the current
   * value of the word.
   */
  @Output('word-change') wordChangeEmitter = new EventEmitter<string>();

  /**
   * Called when the user submits the word (by tapping the last selected dice, or
   * completing a swipe if I ever get this implemented).  Sends the completed word.
   */
  @Output('word-submit') wordSubmitEmitter = new EventEmitter<string>();

  /** All of the dice components contained in the grid */
  @ViewChildren(DiceComponent) diceList!: QueryList<DiceComponent>;
  
  /** The 4 x 4 grid of dice. */
  grid!: DiceModel[][];

  /** The dice that have been selected and are forming part of the current word. */
  selectedDiceList: DiceModel[] = [];

  /** The DiceModels in the grid, mapped to each one's the letter */
  letterDiceMap: {[letter: string]: DiceModel[]} = {};

  /** Exposing the DiceSize enum to the template */
  DiceSize = DiceSize;

  /**
   * If swiping is true then submit at the end of the swipe.  If it's false
   * then a dice click event from the dice component will take care of dice
   * selection / word submit.
   */
  private swiping = false;

  /** Prevents swipe gestures if the user has started tapping out a word. */
  private preventSwipe = false;

  constructor(private config: ConfigService, private gameController: GameController) { };

  ngOnInit(): void {
    this.grid = [];
    for (let y = 0; y < 4; y++) {
      this.grid.push([]);
      for (let x = 0; x < 4; x++) {
        const letter = this.gameController.gridLetters!.charAt(y * 4 + x);
        const diceModel = new DiceModel(x, y, this.gridProps.diceProps, letter.toUpperCase());
        
        // Add diceModel to the grid
        this.grid[y].push(diceModel);
        // Add diceModel to the letterDiceMap
        if (!this.letterDiceMap[letter]) {
          this.letterDiceMap[letter] = [];
        }
        this.letterDiceMap[letter].push(diceModel);
      }
    }
  }

  /**
   * Selects the current dice if there are no letters in the word or if the dice hasn't
   * been selected before and the dice is horizontally, vertically or diagonally adjacent
   * to the last dice selected.
   * 
   * Submits the selected word if the dice selected is the last dice entered.
   * 
   * De-selects the sequence of dice after and including the current dice if the dice the
   * dice has already been selected and isn't the last dice entered.  De-selects the dice
   * if it is the only dice that has been selected.
   * 
   * @param diceCmp The dice component that has been clicked.
   */
  onDiceClick(diceCmp: DiceComponent, touchSource?: boolean): void {
    if (this.disabled) {
      return;
    }
    if (touchSource) {
      console.log('Dice clicked from touch');
    } else {
      console.log('Actual dice click');
    }
    
    let dice = diceCmp.dice;
    let diceIndex = this.selectedDiceList.indexOf(dice);

    if (diceIndex < 0) {
      // Dice not already selected
      // Find last selected in grid and ensure dice is adjacent
      if (!this.selectedDiceList.length || isAdjacent(dice, this.selectedDiceList)) {
        dice.state = DiceState.Selected;
        this.selectedDiceList.push(dice);
        this.emitWordChange();
      }
    } else {
      if (diceIndex === this.selectedDiceList.length - 1) {
        if (diceIndex === 0) {
          // De-select the dice if it is the only dice selected
          this.selectedDiceList[0].state = DiceState.Normal;
          this.selectedDiceList.pop();
          this.emitWordChange();
        
        } else if (this.getWord().length >= this.config.minWordLength) {
          // Submit the word if the word is long enough and you've selected the last
          // letter in the word.
          this.emitWordSubmit();
        }

      } else {
        for (let i = this.selectedDiceList.length - 1; i > diceIndex; i--) {
          // Deselect all after the selected die
          this.selectedDiceList[i].state = DiceState.Normal;
          this.selectedDiceList.pop();
          this.emitWordChange();
        }
      }
    }
    // Wiggle the dice, just for effect
    diceCmp.bump();
  }

  preventScroll(event: TouchEvent) {
    event.preventDefault();
  }

  onMouseDown(event: MouseEvent) {
    if (event.buttons === 1) {
      this.onSwipeStart();
    }
  }

  onSwipeStart() {
    if (this.disabled) {
      return;
    }
    if (this.selectedDiceList.length) {
      this.preventSwipe = true;
    }
  }

  onTouchMove(event: TouchEvent) {
    this.checkCollisions(event.touches[0].clientX, event.touches[0].clientY);
  }

  onMouseMove(event: MouseEvent) {
    if (event.buttons === 1) {
      this.checkCollisions(event.clientX, event.clientY);
    }
  }

  private checkCollisions(clientX: number, clientY: number) {
    if (this.disabled || this.preventSwipe) {
      return;
    }
    const boundsType = this.swiping ? BoundsType.HitBox : BoundsType.FullDice;
    this.swiping = true;
    this.diceList.forEach(diceCmp => diceCmp.checkCollision(clientX, clientY, boundsType));
  }

  onCollision(diceCmp: DiceComponent) {
    if (this.selectedDiceList.length && this.selectedDiceList[this.selectedDiceList.length - 1] === diceCmp.dice) {
      return;
    }
    this.onDiceClick(diceCmp, true);
  }

  onMouseUp(event: MouseEvent) {
    if (event.buttons === 0) {
      this.onSwipeEnd();
    }
  }

  onSwipeEnd() {
    if (this.swiping && !this.preventSwipe) {
      this.emitWordSubmit();
    }
    this.swiping = false;
    this.preventSwipe = false;
  }

  /**
   * De-selects all the selected dice and emits a word change to update listeners
   */
  clearSelectedDice(state: DiceState): void {
    timer(0, 400)
      .pipe(
        take(2),
        map(count => count === 0 ? state : DiceState.Normal)
      )
      .subscribe({
        next: currentState => this.setSelectedDiceState(currentState),
        complete: () => {
          this.selectedDiceList.length = 0;
          this.emitWordChange();
        }
      });
  }

  selectWord(word: string) {
    this.setSelectedDiceState(DiceState.Normal);
    this.selectedDiceList.length = 0;
    const letterDiceMapCopy = JSON.parse(JSON.stringify(this.letterDiceMap));
    this.selectedDiceList = this.wordToDiceList(word, letterDiceMapCopy) || [];
  }

  private setSelectedDiceState(state: DiceState) {
    this.selectedDiceList.forEach(dice => {
      dice.state = state;
    });
  }

  /**
   * Informs word change listeners that the word has changed.
   */
  private emitWordChange(): void {
    let word = this.getWord();
    this.wordChangeEmitter.emit(word);
  }

  /**
   * Informs word submit listeners the the word is to be submitted.
   */
  private emitWordSubmit() {
    let word = this.getWord();
    this.wordSubmitEmitter.emit(word);
  }

  /**
   * Derives the word from the selected dice.
   * 
   * @returns the word derived from the letters of the selected dice.
   */
  private getWord(): string {
    let word = '';
    for (let i = 0; i < this.selectedDiceList.length; i++) {
      word += this.selectedDiceList[i].letter.toLowerCase();
      if (this.selectedDiceList[i].letter === 'Q') {
        word += 'u';
      }
    }
    return word;
  }

  private wordToDiceList(letters: string, letterDiceMap: {[letter: string]: DiceModel[]}, previousDice?: DiceModel): DiceModel[] | undefined {
    if (!letters) {
      return [];
    }

    const letter = letters.charAt(0);
    const remaining = letters.substring(1);

    const diceCandidates = this.letterDiceMap[letter];
    if (!diceCandidates || diceCandidates.length === 0) {
      return undefined;
    }
    
    // find an adjacent dice
    for (let candidateDice of diceCandidates) {
      if (candidateDice.state !== DiceState.Selected && (!previousDice || isAdjacent(previousDice, [candidateDice]))) {
        candidateDice.state = DiceState.Selected;
        const diceList = this.wordToDiceList(remaining, JSON.parse(JSON.stringify(letterDiceMap)), candidateDice);
        if (!diceList) {
          candidateDice.state = DiceState.Normal;
        } else {
          return [candidateDice].concat(diceList);
        }
      }
    }
    return undefined;
  }
}

/**
 * Returns true if the dice is horizontally, vertically or diagonally adjacent to the
 * last dice in the dice list.  Returns false if there are no dice in the dice list.
 * 
 * @param dice a Dice
 * @param diceList a list of Dice to compare to
 * @returns True if dice is adjacent to the last dice in diceList.  False otherwise or
 *          if diceList is empty.
 */
function isAdjacent(dice: DiceModel, diceList: DiceModel[]): boolean {
  if (!diceList || !diceList.length) {
    return false;
  }
  let lastDice = diceList[diceList.length - 1];

  return Math.abs(dice.x - lastDice.x) <= 1 
      && Math.abs(dice.y - lastDice.y) <= 1;
}

// function lettersToDiceList(letters: string, letterDiceMap: ): DiceModel[] {
//   if (!letters) {
//     return [];
//   }
//   // Get the next letter and coordinates of the occurences of that
//   // letter
//   const letter = letters.charAt(0);
//   var coordsList = letterCoords[letter];

//   // If there are occurences of the letter then add the letters in
//   // the list using the coords list.
//   if (coordsList) {
//     return wf.addAdjacentDie(coordsList, diceList, letterList);
//   } else {
//     return [];
//   }
// }
