import { Component, Input } from '@angular/core';
import { DiceComponent } from '../dice/dice.component';
import { DiceModel, DiceSize, DiceOrientation, DiceProps } from '../../model/dice.model';

@Component({
  selector: 'app-dice-text',
  standalone: true,
  imports: [DiceComponent],
  templateUrl: './dice-text.component.html',
  styleUrl: './dice-text.component.css'
})
export class DiceTextComponent {
  @Input({transform: mapStringToDice}) text!: DiceModel[]; 
}

function mapStringToDice(value: string): DiceModel[] {
  let diceText: DiceModel[] = [];
  const props: DiceProps = {
    size: DiceSize.Medium,
    deviationDegrees: 0,
    orientation: DiceOrientation.Normal
  };
  for (let i = 0; i < value.length; i++) {
    diceText.push(new DiceModel(i, 0, props, value.charAt(i)));
  }
  return diceText;
}
