<div class="container">
    <div id="menus" [class.overlay-darken]="showingReadySetGo">
        
        <br/>
        <h4 class="centred-title">Rick's</h4>
        <app-boggle-text text="Dicey Word Game"></app-boggle-text>
        <br/><br/>

        @if (shownSection === sectionType.MainMenu) {
            <div id="main-menu">
                <button type="button" [disabled]="submitButtonsDisabled" (click)="shownSection = sectionType.StartPartyOptions">Start party / solo</button>
                <button type="button" (click)="joinParty()">Join party</button>
                <button type="button" (click)="solve()">Solver</button>
                <button type="button" (click)="showAbout()">About</button>
            </div>
        }

        @if (shownSection === sectionType.StartPartyOptions || shownSection === sectionType.JoinPartyOptions) {

            <form id="player-details" #playerDetailsForm="ngForm" (ngSubmit)="submitPartyOptions()">
                @if (shownSection === sectionType.JoinPartyOptions) {
                    <div class="heading">Enter party code</div>
                    <input class="party-code" stype="text" required id="partyCode" name="partyCode" [(ngModel)]="partyCode" maxlength="4">
                }
                <div class="heading">Your name</div>
                <div>
                    <input type="text" required id="playerName" name="playerName" [(ngModel)]="playerName" 
                        (input)="updateInitials()" #playerNameNgModel="ngModel"/>
                    @if (playerNameNgModel.invalid && (playerNameNgModel.dirty || playerNameNgModel.touched)) {
                        @if (playerNameNgModel.hasError('required')) {
                            <div class="error">Name is required.</div>
                        }
                    }
                </div>
                <div>
                    <app-dice [dice]="playerDice"></app-dice>
                </div>
                @if (shownSection === sectionType.StartPartyOptions) {
                    <div class="round-options">
                        <app-round-options [(options)]="roundOptions" (editing)="setRoundEditing($event)"></app-round-options>
                    </div>
                }
                <button type="submit" [disabled]="submitButtonsDisabled || !playerDetailsForm.form.valid || roundEditing">
                    {{shownSection === sectionType.StartPartyOptions ? 'Start Party!' : 'Join Party!'}}
                </button>
                <button type="button" (click)="gotoMainMenu()">Main Menu</button>
            </form>

        } @else if (shownSection === sectionType.StartParty) {

            <div id="start-party">
                <div>Your party code</div>
                <h2>{{partyCode}}</h2>
                <p>Share this code with the other players<br>
                in your party so they can join.</p>
                <p>When everyone's joined or you're going solo,<br>
                select Start Round!</p>
                <br>
                <p>Players</p>
                <div class="party-players">
                    @for (partyPlayer of partyPlayers; track partyPlayer.name) {
                        <div>
                            <div>{{partyPlayer.name}}</div>
                            <app-dice [dice]="partyPlayer.avatar"></app-dice>
                        </div>
                    }
                </div>
                <button type="button" (click)="startRound()" [disabled]="!roundReady || submitButtonsDisabled">
                    {{roundReady ? 'Start Round!' : 'Generating Round...'}}</button>
                <button type="button" (click)="gotoMainMenu()">Main Menu</button>
            </div>

        } @else if (shownSection === sectionType.JoinParty) {

            <div id="start-party">
                <div>You've joined</div>
                <h2>{{partyCode}}</h2>
                <p>Share this code with the other players<br>
                in your party so they can join.</p>
                <p>Waiting on {{hostPlayerName}} to start the round.</p>
                <br>
                <p>Players</p>
                <div class="party-players">
                    @for (partyPlayer of partyPlayers; track partyPlayer.name) {
                        <div>
                            <div>{{partyPlayer.name}}</div>
                            <app-dice [dice]="partyPlayer.avatar"></app-dice>
                        </div>
                    }
                </div>
                <br>
                <button type="button" (click)="gotoMainMenu()">Main Menu</button>
            </div>
        }
    </div>
    <app-ready-set-go [round-number]="roundNumber!" (state-change)="readySetGoChange($event)"></app-ready-set-go>
</div>
