import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-boggle-text',
  standalone: true,
  imports: [],
  templateUrl: './boggle-text.component.svg',
  styleUrl: './boggle-text.component.css'
})
export class BoggleTextComponent {
  @Input() text!: string;
}
