export enum DiceSize {
    Large,
    Medium,
    Small,
    XSmall
}

export enum DiceOrientation {
    Normal,
    Random
}

export enum DiceState {
    Normal,
    Selected,
    Correct,
    Incorrect,
    Inactive
}
  
export interface DiceProps {
    size: DiceSize;
    deviationDegrees: number;
    orientation: DiceOrientation;
}

export class DiceModel {
    // A dice has no business knowing its x and y coords, but I'm in a hurry.
    
    /** x coordinate of the dice in the grid. */
    x: number;

    /** y coordinate of the dice in the grid. */
    y: number;

    /** The charater shown on the dice. */
    letter: string;

    /** The optional second character shown on the dice. */
    secondLetter: string | undefined;

    /** The way up the dice is facing: 0, 90, 180 or 270 degrees. */
    orientationDegrees: number;

    /** The angle in degrees in which a dice can wiggle when bumped */
    deviationDegrees: number;

    /** The size of the dice */
    size: DiceSize;

    /** The state of the dice influencing its colour */
    state: DiceState;

    constructor(x: number, y: number, props: DiceProps, letter: string, secondLetter?: string) {
        this.y = y;
        this.x = x;
        this.letter = letter.toUpperCase();
        this.secondLetter = secondLetter?.toLowerCase();
        this.orientationDegrees = props.orientation === DiceOrientation.Random ? Math.floor(Math.random() * 4) * 90 : 0;
        this.deviationDegrees = props.deviationDegrees;
        this.size = props.size;
        this.state = DiceState.Normal;
    }

    static forText(letter: string, secondLetter?: string) {
        const props: DiceProps = {
            size: DiceSize.XSmall,
            deviationDegrees: 0,
            orientation: DiceOrientation.Normal
        };
        return new DiceModel(0, 0, props, letter, secondLetter);
    }
}