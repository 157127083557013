<div class="container">
    <div class="option">
        <div class="option-heading">Game length</div>
        @if (gameLengthEditing) {
            <div class="option-combo">
                @for (gameLengthOption of gameLengthOptions; track gameLengthOption.text) {
                    <div [class.selected]="gameLengthText === gameLengthOption.text" 
                         (click)="setGameLength(gameLengthOption)">
                        {{gameLengthOption.text}}
                    </div>
                }
            </div>
        } @else {
            <div class="option-choice" (click)="editGameLength()">{{gameLengthText}}</div>
        }
    </div>
</div>
