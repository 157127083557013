<div class="container">
    @if (resultsMode === true) {
        <div class="filters">
            <input type="radio" name="word-group" id="all-words" value="all-words" (click)="filterWords('all')" checked>
            <label for="all-words">all words</label>
            <input type="radio" name="word-group" id="guessed" value="guessed" (click)="filterWords('guessed')">
            <label for="guessed">guessed</label>
            <input type="radio" name="word-group" id="missed" value="missed" (click)="filterWords('missed')">
            <label for="missed">missed</label>
            @if (players.length > 1) {
                @for (player of players; track player.playerId) {
                    <input type="radio" name="word-group" id="{{player.playerId}}" value="{{player.playerId}}" (click)="filterWords(player.playerId)">
                    <label class="dicey-label" for="{{player.playerId}}"><app-dice [dice]="playerAvatars[player.playerId]"></app-dice></label>
                }
            }
        </div>
    }
    <div class="score-ordered-container">
        @for (score of scorePoints; track score) {
            @if (wordTotals[score]) {
                <div class="score-group">
                    <div>{{score}} pt{{score > 1 ? 's' : ''}}</div>
                    <div>{{scoreListCount[score]}}/{{wordTotals[score]}}</div>
                    @for (wordDetails of wordsByScore[score]; track wordDetails.word) {
                        <div class="valid-word"
                                [class.my-word]="wordDetails.playerIds.indexOf(myPlayerId) >= 0"
                                [class.results-mode]="resultsMode"
                                [class.selected-word]="wordDetails.word === selectedWord">
                            <div class="word-details" (click)="selectWord(wordDetails.word)"
                                    [class.cancelled-guess]="wordDetails.playerIds.length > 1 && wordDetails.playerIds.indexOf(myPlayerId) >= 0">
                                <div>{{wordDetails.word}}</div>
                                @for (playerId of wordDetails.playerIds; track playerId) {
                                    @if (myPlayerId !== playerId) {
                                        &nbsp;<app-dice [dice]="playerAvatars[playerId]"></app-dice>
                                    }
                                }
                            </div>
                            @if (wordDetails.word === selectedWord) {
                                <div class="word-definition">
                                    <a href="https://www.google.com/search?q={{wordDetails.word}}+definition" target="_blank">definition</a>
                                </div>
                            }
                        </div>
                    }
                </div>
            }
        }
    </div>
    @if (invalidWordList.length) {
        <div class="invalid-words">
            <p>Invalid words</p>
            <div class="word-list">
                @for (invalidWord of invalidWordList; track invalidWord) {
                    <div class="invalid-word" [class.selected-word]="invalidWord === selectedWord">
                        <div (click)="selectWord(invalidWord)">
                            {{invalidWord}}
                        </div>
                        @if (invalidWord === selectedWord) {
                            <div class="word-definition">
                                <a href="https://www.google.com/search?q={{invalidWord}}+definition" target="_blank">definition?</a>
                            </div>
                        }
                    </div>
                }
            </div>
        </div>
    }
</div>
