<div id="container"
    [class.x-small]="dice.size === DiceSize.XSmall"
    [class.small]="dice.size === DiceSize.Small"
    [class.medium]="dice.size === DiceSize.Medium"
    [class.selected]="dice.state === DiceState.Selected"
    [class.correct]="dice.state === DiceState.Correct"
    [class.incorrect]="dice.state === DiceState.Incorrect"
    [class.inactive]="dice.state === DiceState.Inactive"
>
    <div [ngStyle]="style" #diceImage>
        {{dice.letter}}<span [hidden]="dice.letter !== 'Q' && !dice.secondLetter">{{dice.secondLetter || 'u'}}</span>
    </div>
</div>
