import { Component, OnInit, ViewChild } from '@angular/core';
import { GridComponent } from '../common/grid/grid.component';
import { GridProps } from '../common/grid/grid.component';
import { DiceComponent } from '../common/dice/dice.component';
import { DiceModel, DiceSize, DiceOrientation } from '../model/dice.model';
import { PlayerDetails, WordDetails, WordModel } from '../model/party.model';
import { GameController, GameState } from '../service/game-controller.service';
import { BoggleTextComponent } from '../common/boggle-text/boggle-text.component';
import { DiceTextComponent } from '../common/dice-text/dice-text.component';
import { RoundOptions, RoundOptionsComponent } from '../common/round-options/round-options.component';
import { ConfigService } from '../service/config.service';
import { ReadySetGoComponent, ReadySetGoState } from '../common/ready-set-go/ready-set-go.component';
import { WordListComponent } from '../common/word-list/word-list.component';

enum ViewType {
  Results = 'RESULTS',
  NextRound = 'NEXT_ROUND',
  Exit = 'EXIT'
}

enum RankType {
  RoundScore,
  TotalScore
}

@Component({
  selector: 'app-results-screen',
  standalone: true,
  imports: [GridComponent, DiceComponent, BoggleTextComponent, DiceTextComponent, RoundOptionsComponent, ReadySetGoComponent, WordListComponent],
  templateUrl: './results-screen.component.html',
  styleUrl: './results-screen.component.css'
})
export class ResultsScreenComponent implements OnInit {
  
  @ViewChild(GridComponent) gridCmp!: GridComponent;
  @ViewChild(ReadySetGoComponent) readySetGoCmp!: ReadySetGoComponent;

  // Expose enums to the template
  ViewType = ViewType;
  RankType = RankType;

  gridProps: GridProps = {
    diceProps: {
      size: DiceSize.Small,
      deviationDegrees: 4,
      orientation: DiceOrientation.Random
    }
  }

  /** The player ID for my player */
  myPlayerId = '';

  /** True if I am the host player in the party.  False if I am not. */
  amIHost = false;

  /** The name of the host player */
  hostPlayerName = '';

  /** Number of round just ended */
  roundNum = 1;

  /** Round players ordered by score */
  players: PlayerDetails[] = [];

  /** Type of ranking displayed */
  rankType = RankType.RoundScore;

  /** Map of player IDs to player avatar DiceModels */
  playerAvatars: {[playerId: string]: DiceModel} = {};

  /** The selected view */
  selectedView = ViewType.Results;

  /** Valid words, their scores and who guessed them.  This is the filtered list */
  wordDetailsList: WordDetails[] = [];

  /** The number of words that can be found for each score */
  wordTotals: {[score: string]: number} = {};

  /** Invalid words */
  invalidWordList: string[] = [];

  /** True to disable submit buttons and prevent unwanted clicks */
  submitButtonsDisabled = false;

  /** Round options for the next round */
  nextRoundOptions: RoundOptions = { gameLength: 60 };

  /** True if the next round options are being edited */
  nextRoundEditing = false;

  /** True if we're showing the Ready Set Go countdown */
  showingReadySetGo = false;

  rankSuffix = ['st', 'nd', 'rd'];

  constructor(private gameController: GameController, private config: ConfigService) { }

  /** Words in the round and the players that guessed them */
  ngOnInit(): void {
    this.myPlayerId = this.gameController.playerId;
    this.roundNum = this.gameController.roundNumber!;
    this.rankScores(this.rankType);
    this.players = sortPlayers(this.gameController.playerDetailsList, RankType.RoundScore);
    this.playerAvatars = playerAvatarsMap(this.players);
    this.wordDetailsList = this.gameController.wordDetailsList;
    this.invalidWordList = this.gameController.invalidWords;
    this.amIHost = this.gameController.amIHost;
    this.hostPlayerName = this.gameController.hostPlayerName;
    
    // this.myPlayerId = testMyPlayerId();
    // this.roundNum = testRoundNum();
    // this.rankScores(this.rankType);
    // this.playerAvatars = testPlayerAvatars();
    // this._completeWordDetails = testWords();
    // this.wordDetailsList = JSON.parse(JSON.stringify(this._completeWordDetails));
    // this.invalidWordList = testInvalidWords();
    // this.amIHost = testAmIHost();
    // this.hostPlayerName = testHostPlayerName();

    this.nextRoundOptions.gameLength = this.config.gameTimeSecs;
    this.wordTotals = this.gameController.wordTotals;

    this.gameController.roundStartingNotifier.subscribe(gameStartTime => {
      this.roundNum = this.gameController.roundNumber!;
      this.readySetGoCmp.readySetGoBy(gameStartTime);
    })
  }

  rankScores(rankType: RankType) {
    this.rankType = rankType;
    this.players = sortPlayers(this.gameController.playerDetailsList, rankType);
    // this.players = sortPlayers(testPlayers(), rankType);
  }

  selectWord(word: string) {
    this.gridCmp.selectWord(word);
  }

  startRound() {
    this.submitButtonsDisabled = true;
    this.config.gameTimeSecs = this.nextRoundOptions.gameLength;
    this.gameController.startRound();
  }

  quit() {
    this.gameController.nextGameState(GameState.Title);
  }

  readySetGoChange(state: ReadySetGoState) {
    if (state === ReadySetGoState.Ready) {
      this.showingReadySetGo = true;
    } else if (state === ReadySetGoState.Complete) {
      this.gameController.nextGameState(GameState.Play);
    }
  }
}

function wordsMapToArray(wordsMap: {[word:string]: WordModel}): WordDetails[] {
  const wordDetailArray: WordDetails[] = [];
  for (let [word, wordModel] of Object.entries(wordsMap)) {
    wordDetailArray.push({
      word,
      score: wordModel.score,
      playerIds: wordModel.players
    })
  }
  return wordDetailArray;
}

function sortPlayers(players: PlayerDetails[], scoreType: RankType): PlayerDetails[] {
  if (scoreType === RankType.RoundScore) {
    return players.sort((a, b) => a.score > b.score ? -1 : a.score === b.score ? 0 : 1);
  }
  return players.sort((a, b) => a.total > b.total ? -1 : a.total === b.total ? 0 : 1);
}

function playerAvatarsMap(playerDetailsList: PlayerDetails[]): {[playerId: string]: DiceModel} {
  const playerAvatars: {[playerId: string]: DiceModel} = {};
  for (let playerDetails of playerDetailsList) {
    playerAvatars[playerDetails.playerId] = playerDetails.avatar;
  }
  return playerAvatars;
}

//---------------------------------------------------------------------------------------//

function testMyPlayerId(): string {
  return 'OQDEUCKY';
}

function testPlayers(): PlayerDetails[] {
  return [{
    playerId: 'OQDEUCKY',
    name: 'Rickhvjhhvjjhjv',
    avatar: DiceModel.forText('R', 'k'),
    score: 7,
    total: 17
  },
  {
    playerId: 'ABCDEFGH',
    name: 'Sam',
    avatar: DiceModel.forText('S', 'm'),
    score: 9,
    total: 16
  },
  {
    playerId: 'ASDFGHJK',
    name: 'Max',
    avatar: DiceModel.forText('M', 'x'),
    score: 8,
    total: 15
  }
  ];
}

function testAmIHost(): boolean {
  return true;
}

function testRoundNum(): number {
  return 1;
}

function testHostPlayerName(): string {
  return 'Sam';
}

function testPlayerAvatars(): {[playerId: string]: DiceModel} {
  return {
    'OQDEUCKY': DiceModel.forText('R', 'k'),
    'ABCDEFGH': DiceModel.forText('S', 'm'),
    'ASDFGHJK': DiceModel.forText('M', 'x')
  }
}

function testInvalidWords(): string[] {
  return ['wut', 'flob'];
}

function testWords(): WordDetails[] {
  const wordsMap: {[word:string]: WordModel} = {
    "foen": {
      "score": 1,
      "players": []
    },
    "newed": {
      "score": 2,
      "players": []
    },
    "ewt": {
      "score": 1,
      "players": []
    },
    "def": {
      "score": 1,
      "players": []
    },
    "dee": {
      "score": 1,
      "players": []
    },
    "lote": {
      "score": 1,
      "players": []
    },
    "nid": {
      "score": 1,
      "players": []
    },
    "bio": {
      "score": 1,
      "players": []
    },
    "dei": {
      "score": 1,
      "players": []
    },
    "nie": {
      "score": 1,
      "players": []
    },
    "den": {
      "score": 1,
      "players": []
    },
    "bowned": {
      "score": 3,
      "players": []
    },
    "endew": {
      "score": 2,
      "players": []
    },
    "roil": {
      "score": 1,
      "players": []
    },
    "tee": {
      "score": 1,
      "players": []
    },
    "dew": {
      "score": 1,
      "players": []
    },
    "flor": {
      "score": 1,
      "players": []
    },
    "ten": {
      "score": 1,
      "players": []
    },
    "boil": {
      "score": 1,
      "players": []
    },
    "flow": {
      "score": 1,
      "players": []
    },
    "fro": {
      "score": 1,
      "players": []
    },
    "lowned": {
      "score": 3,
      "players": []
    },
    "tew": {
      "score": 1,
      "players": []
    },
    "indew": {
      "score": 2,
      "players": []
    },
    "floe": {
      "score": 1,
      "players": []
    },
    "lownd": {
      "score": 2,
      "players": []
    },
    "lowne": {
      "score": 2,
      "players": []
    },
    "wolfed": {
      "score": 3,
      "players": []
    },
    "wend": {
      "score": 1,
      "players": []
    },
    "enew": {
      "score": 1,
      "players": []
    },
    "lobi": {
      "score": 1,
      "players": []
    },
    "lob": {
      "score": 1,
      "players": []
    },
    "obtend": {
      "score": 3,
      "players": []
    },
    "rolf": {
      "score": 1,
      "players": []
    },
    "flote": {
      "score": 2,
      "players": []
    },
    "eorl": {
      "score": 1,
      "players": []
    },
    "toe": {
      "score": 1,
      "players": []
    },
    "two": {
      "score": 1,
      "players": []
    },
    "lor": {
      "score": 1,
      "players": []
    },
    "nide": {
      "score": 1,
      "players": []
    },
    "lot": {
      "score": 1,
      "players": []
    },
    "low": {
      "score": 1,
      "players": []
    },
    "twee": {
      "score": 1,
      "players": []
    },
    "tor": {
      "score": 1,
      "players": []
    },
    "wee": {
      "score": 1,
      "players": []
    },
    "wed": {
      "score": 1,
      "players": [
        "OQDEUCKY"
      ]
    },
    "teend": {
      "score": 2,
      "players": []
    },
    "rote": {
      "score": 1,
      "players": []
    },
    "tow": {
      "score": 1,
      "players": []
    },
    "weid": {
      "score": 1,
      "players": []
    },
    "bowet": {
      "score": 2,
      "players": []
    },
    "wen": {
      "score": 1,
      "players": []
    },
    "wolf": {
      "score": 1,
      "players": []
    },
    "wet": {
      "score": 1,
      "players": []
    },
    "townie": {
      "score": 3,
      "players": []
    },
    "rownd": {
      "score": 2,
      "players": []
    },
    "blown": {
      "score": 2,
      "players": []
    },
    "toed": {
      "score": 1,
      "players": []
    },
    "towed": {
      "score": 2,
      "players": []
    },
    "bowed": {
      "score": 2,
      "players": []
    },
    "eine": {
      "score": 1,
      "players": []
    },
    "flowed": {
      "score": 3,
      "players": []
    },
    "rowen": {
      "score": 2,
      "players": []
    },
    "owe": {
      "score": 1,
      "players": []
    },
    "blot": {
      "score": 1,
      "players": []
    },
    "die": {
      "score": 1,
      "players": []
    },
    "feet": {
      "score": 1,
      "players": []
    },
    "rolfed": {
      "score": 3,
      "players": []
    },
    "rowed": {
      "score": 2,
      "players": []
    },
    "fed": {
      "score": 1,
      "players": []
    },
    "frow": {
      "score": 1,
      "players": []
    },
    "lib": {
      "score": 1,
      "players": []
    },
    "diene": {
      "score": 2,
      "players": []
    },
    "fee": {
      "score": 1,
      "players": []
    },
    "feen": {
      "score": 1,
      "players": []
    },
    "own": {
      "score": 1,
      "players": []
    },
    "din": {
      "score": 1,
      "players": []
    },
    "ned": {
      "score": 1,
      "players": []
    },
    "woe": {
      "score": 1,
      "players": []
    },
    "owt": {
      "score": 1,
      "players": []
    },
    "fen": {
      "score": 1,
      "players": [
        "OQDEUCKY"
      ]
    },
    "nef": {
      "score": 1,
      "players": []
    },
    "nee": {
      "score": 1,
      "players": []
    },
    "wof": {
      "score": 1,
      "players": []
    },
    "froe": {
      "score": 1,
      "players": []
    },
    "lowt": {
      "score": 1,
      "players": []
    },
    "feni": {
      "score": 1,
      "players": []
    },
    "bowne": {
      "score": 2,
      "players": []
    },
    "few": {
      "score": 1,
      "players": []
    },
    "fend": {
      "score": 1,
      "players": []
    },
    "blow": {
      "score": 1,
      "players": []
    },
    "foil": {
      "score": 1,
      "players": []
    },
    "net": {
      "score": 1,
      "players": []
    },
    "wot": {
      "score": 1,
      "players": []
    },
    "new": {
      "score": 1,
      "players": [
        "OQDEUCKY"
      ]
    },
    "lowe": {
      "score": 1,
      "players": []
    },
    "town": {
      "score": 1,
      "players": []
    },
    "need": {
      "score": 1,
      "players": []
    },
    "ute": {
      "score": 1,
      "players": []
    },
    "wrote": {
      "score": 2,
      "players": []
    },
    "lown": {
      "score": 1,
      "players": []
    },
    "nied": {
      "score": 1,
      "players": []
    },
    "roed": {
      "score": 1,
      "players": []
    },
    "ide": {
      "score": 1,
      "players": []
    },
    "nief": {
      "score": 1,
      "players": []
    },
    "blowed": {
      "score": 3,
      "players": []
    },
    "owed": {
      "score": 1,
      "players": []
    },
    "fob": {
      "score": 1,
      "players": [
        "OQDEUCKY"
      ]
    },
    "boi": {
      "score": 1,
      "players": []
    },
    "foe": {
      "score": 1,
      "players": []
    },
    "ween": {
      "score": 1,
      "players": []
    },
    "frowned": {
      "score": 5,
      "players": []
    },
    "bor": {
      "score": 1,
      "players": []
    },
    "deen": {
      "score": 1,
      "players": []
    },
    "rob": {
      "score": 1,
      "players": []
    },
    "bot": {
      "score": 1,
      "players": [
        "OQDEUCKY"
      ]
    },
    "for": {
      "score": 1,
      "players": [
        "OQDEUCKY"
      ]
    },
    "roe": {
      "score": 1,
      "players": []
    },
    "idee": {
      "score": 1,
      "players": []
    },
    "weet": {
      "score": 1,
      "players": []
    },
    "bow": {
      "score": 1,
      "players": []
    },
    "denet": {
      "score": 2,
      "players": []
    },
    "torr": {
      "score": 1,
      "players": []
    },
    "een": {
      "score": 1,
      "players": []
    },
    "flown": {
      "score": 2,
      "players": []
    },
    "tend": {
      "score": 1,
      "players": []
    },
    "deni": {
      "score": 1,
      "players": []
    },
    "dine": {
      "score": 1,
      "players": [
        "OQDEUCKY"
      ]
    },
    "oil": {
      "score": 1,
      "players": []
    },
    "lowed": {
      "score": 2,
      "players": []
    },
    "dene": {
      "score": 1,
      "players": []
    },
    "orfe": {
      "score": 1,
      "players": []
    },
    "rot": {
      "score": 1,
      "players": []
    },
    "tewed": {
      "score": 2,
      "players": []
    },
    "end": {
      "score": 1,
      "players": []
    },
    "weed": {
      "score": 1,
      "players": []
    },
    "row": {
      "score": 1,
      "players": []
    },
    "ene": {
      "score": 1,
      "players": []
    },
    "deet": {
      "score": 1,
      "players": []
    },
    "tene": {
      "score": 1,
      "players": []
    },
    "orf": {
      "score": 1,
      "players": []
    },
    "teed": {
      "score": 1,
      "players": []
    },
    "newt": {
      "score": 1,
      "players": []
    },
    "frown": {
      "score": 2,
      "players": []
    },
    "toil": {
      "score": 1,
      "players": []
    },
    "teen": {
      "score": 1,
      "players": []
    },
    "rowt": {
      "score": 1,
      "players": []
    },
    "tween": {
      "score": 2,
      "players": []
    },
    "ewe": {
      "score": 1,
      "players": []
    },
    "townee": {
      "score": 3,
      "players": []
    },
    "owned": {
      "score": 2,
      "players": []
    },
    "obi": {
      "score": 1,
      "players": []
    },
    "tweed": {
      "score": 2,
      "players": []
    }
  };
  return wordsMapToArray(wordsMap);
}
