import { Injectable } from '@angular/core';

const GAME_TIME_SECS = 'gameTimeSecs';
const MIN_WORD_LENGTH = 'minWordLength';
const PLAYER_DETAILS = 'playerDetails';

const DEFAULT_GAME_TIME_SECS = 60;
const DEFAULT_MIN_WORD_LENGTH = 3;

@Injectable({
  providedIn: 'root'
})
export class ConfigService {

  private _gameTimeSecs: number;
  private _minWordLength: number;
  private _playerDetails: PlayerDetails | undefined;

  /**
   * Amount of time that a game round lasts
   */
  get gameTimeSecs() {
    return this._gameTimeSecs;
  }

  set gameTimeSecs(value: number) {
    this._gameTimeSecs = value;
    localStorage.setItem(GAME_TIME_SECS, String(value));
  }

  /**
   * The number of dice that must be selected before you can submit the word.
   */
  get minWordLength() {
    return this._minWordLength;
  }

  set minWordLength(value: number) {
    this._minWordLength = value;
    localStorage.setItem(MIN_WORD_LENGTH, String(value));
  }

  /**
   * The name and image details of the player.
   */
  get playerDetails(): PlayerDetails | undefined {
    return this._playerDetails;
  };

  set playerDetails(value: PlayerDetails) {
    this._playerDetails = value;
    localStorage.setItem(PLAYER_DETAILS, JSON.stringify(value));
  }

  constructor() {
    this._gameTimeSecs = localStorage.getItem(GAME_TIME_SECS) ? Number.parseInt(localStorage.getItem(GAME_TIME_SECS)!) : DEFAULT_GAME_TIME_SECS;
    this._minWordLength = localStorage.getItem(MIN_WORD_LENGTH) ? Number.parseInt(localStorage.getItem(MIN_WORD_LENGTH)!) : DEFAULT_MIN_WORD_LENGTH;
    this._playerDetails = localStorage.getItem(PLAYER_DETAILS) ? JSON.parse(localStorage.getItem(PLAYER_DETAILS)!) : undefined;
  }
}

export enum Colour {
  Black = 'BLACK',
  Blue = 'BLUE',
  Green = 'GREEN',
  Orange = 'ORANGE',
  Pink = 'PINK',
  Purple = 'PURPLE',
  Red = 'RED',
  White = 'WHITE',
  Yellow = 'YELLOW'
}

export interface PlayerDetails {
  name: string;
  initials: string;
  textColour: Colour;
  backgroundColour: Colour;
}
