import { NgStyle } from '@angular/common';
import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { DiceModel, DiceSize, DiceState } from '../../model/dice.model';

export enum BoundsType {
  FullDice,
  HitBox
}

@Component({
  selector: 'app-dice',
  standalone: true,
  imports: [NgStyle],
  templateUrl: './dice.component.html',
  styleUrl: './dice.component.css'
})
export class DiceComponent implements OnInit, AfterViewInit{
  
  /**
   * Dice model containing the letter to show for the dice, it's orientation and
   * whether the dice is selected.
   */
  @Input({ required: true }) dice!: DiceModel;

  /** Emits events when touch move actions occur within this dice' hit box */
  @Output('collision') collisionEventEmitter = new EventEmitter<DiceComponent>();

  /** The div element rendering the dice image */
  @ViewChild('diceImage') diceImage!: ElementRef<HTMLDivElement>;

  // Dynamic styling of the dice
  style: any;

  // Make the DiceSize and DiceState enums available to the template
  DiceSize = DiceSize;
  DiceState = DiceState;

  // Width of the hit box square that will select this dice when swiping
  private hitBoxWidth: number = 0;

  // Distance of the hit box from the edges of the dice
  private hitBoxOffset: number = 0;
  
  ngOnInit(): void {
    this.bump();
  }

  ngAfterViewInit(): void {
    const diceBounds = this.diceImage.nativeElement.getBoundingClientRect();
    const width = diceBounds.right - diceBounds.left;
    this.hitBoxWidth = width * 0.7;
    this.hitBoxOffset = (width - this.hitBoxWidth) / 2;
  }

  /**
   * Styles letter requiring an underline
   */
  underlineStyle(): string {
    return this.dice.letter === 'M'
        || this.dice.letter === 'W'
        || this.dice.letter === 'N'
        || this.dice.letter === 'Z' ? 'underline' : 'none';
  }

  /**
   * Rotates the cube a fraction in the clockwise or anticlockwise directions
   * using orientationDegrees as the base angle and adding a random angle based
   * on deviationDegrees.
   * 
   * If orientation-degrees is 90 and deviation-degrees is 5 then
   * calling the bump() function will pick a random actual rotation of the dice
   * between 85 degrees and 95 degrees.
   */
  bump(): void {
    let rotationDegrees = this.dice.orientationDegrees + 
      Math.floor(Math.random() * this.dice.deviationDegrees * 2) - this.dice.deviationDegrees;
    this.style = {
      'background' : 'url(/assets/dice' + this.dice.orientationDegrees + 'deg.svg)',
      '-ms-transform' : 'rotate(' + rotationDegrees + 'deg)',
      '-webkit-transform' : 'rotate(' + rotationDegrees + 'deg)',
      'transform' : 'rotate(' + rotationDegrees + 'deg)',
      'text-decoration' : this.underlineStyle()
    }
  }

  /**
   * Checks if the touch event occurs within the bounds of the dice.  If a collision is detected
   * then an event is sent via the dice component's collision event.
   * 
   * @param clientX x coordinate of the event
   * @param clientY y coordinate of the event
   * @param boundsType FullDice for a full bounds check; HitBox to check only within the dice hit box
   */
  checkCollision(clientX: number, clientY: number, boundsType: BoundsType) {
    const diceBounds = this.diceImage.nativeElement.getBoundingClientRect();

    let hitX, hitY;
    if (boundsType === BoundsType.FullDice) {
      hitX = diceBounds.left < clientX && clientX < diceBounds.right;
      hitY = diceBounds.top < clientY && clientY < diceBounds.bottom;
    } else {
      hitX = diceBounds.left + this.hitBoxOffset < clientX && clientX < diceBounds.left + this.hitBoxOffset + this.hitBoxWidth;
      hitY = diceBounds.top + this.hitBoxOffset < clientY && clientY < diceBounds.top + this.hitBoxOffset + this.hitBoxWidth;
    }
    
    if (hitX && hitY) {
      this.collisionEventEmitter.next(this);
    }
  }
}
