import { NgFor } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { GameController } from '../../service/game-controller.service';
import { PlayerDetails, WordDetails, WordModel } from '../../model/party.model';
import { DiceModel } from '../../model/dice.model';
import { DiceComponent } from '../../common/dice/dice.component';

const WordFilterTypes = new Set(['all', 'guessed', 'missed']);

@Component({
  selector: 'app-word-list',
  standalone: true,
  imports: [NgFor, DiceComponent],
  templateUrl: './word-list.component.html',
  styleUrl: './word-list.component.css'
})
export class WordListComponent implements OnInit {
  
  /** The full list of words either entered passed to the component or added by the player */
  @Input({ alias: 'word-list' }) wordList: WordDetails[] = [];

  /** A list of string value words to be displayed in the invalid words section */
  @Input({ alias: 'invalid-words'}) invalidWordList: string[] = [];

  /** Called when words are selected or unselected in the word list */
  @Output('word-select') wordSelectEmitter = new EventEmitter<string>();

  /** Used to lookup the scores for each word */
  wordDetailsMap: { [word:string]: WordModel; } = {};

  /** The number of words that can be found for each score */
  wordTotals: {[scorePoints: number]: number} = {};

  /** Words grouped by score */
  wordsByScore: {[scorePoints: number]: WordDetails[]} = {
    1: [], 2: [], 3: [], 5: [], 11:[]
  };

  /** Possible score points for ease of iteration in the template */
  scorePoints = [1, 2, 3, 5, 11];

  /** Player IDs mapped to the DiceModel representing the player */
  playerAvatars: {[playerId: string]: DiceModel} = {};

  /** Results mode displays filters and allows selection of words */
  resultsMode = false;

  /** ID of this player */
  myPlayerId = '';

  /** Number of guessed words for each score */
  scoreListCount: { [score: number]: number } = {
    1: 0, 2: 0, 3: 0, 5: 0, 11: 0
  }

  /** Filter to be applied to word results */
  wordFilter = 'all';

  /** Round players */
  players: PlayerDetails[] = [];

  /** The word that has been selected in the wordDetailsList */
  selectedWord: string = '';

  constructor(private gameController: GameController) { }

  ngOnInit(): void {
    this.wordDetailsMap = this.gameController.wordDetailsMap;
    this.wordTotals = this.gameController.wordTotals;
    this.playerAvatars = this.gameController.playerAvatarsMap;
    this.myPlayerId = this.gameController.playerId;
    this.players = this.gameController.playerDetailsList;
    
    if (this.wordList.length) {
      this.resultsMode = true;
      this.filterWords(this.wordFilter);
    }
  }

  /**
   * Adds a row to the word list and calculates the word columns to display
   * 
   * @param word the word to be added 
   */
  addWord(word: string) {
    if (!this.wordList.filter(wordDetails => wordDetails.word === word).length) {
      const score = this.wordDetailsMap[word].score;
      const wordDetails = { word, score, playerIds: this.wordDetailsMap[word].players};
      this.wordList.push(wordDetails);
      this.scoreListCount[score]++;
      this.addWordDetails(score, wordDetails);
    }
  }

  addPlayerToWord(playerId: string, word: string) {
    if (playerId !== this.gameController.playerId) {
      const wordDetails = this.wordList.filter(wd => wd.word === word)[0];
      if (wordDetails && wordDetails.playerIds.indexOf(playerId) < 0) {
        wordDetails.playerIds.push(playerId);
      }
    }
  }

  filterWords(wordFilter: string) {
    this.wordFilter = wordFilter;

    const countPlayerId = WordFilterTypes.has(wordFilter) ? this.myPlayerId : wordFilter;

    for (let scoreList of this.scorePoints) {
      this.wordsByScore[scoreList] = [];
      this.scoreListCount[scoreList] = 0;
    }
    for (let wordDetails of this.wordList) {
      if (this.includeWord(wordDetails)) {
        const score = this.wordDetailsMap[wordDetails.word].score;
        this.wordsByScore[score].push(wordDetails);
        if (wordDetails.playerIds.indexOf(countPlayerId) >= 0) {
          this.scoreListCount[score]++;
        }
      }
    }
    for (let scoreList of Object.values(this.wordsByScore)) {
      scoreList.sort((a, b) => a.word < b.word ? -1 : 1);
    }
  }

  clearWordsByScores() {
    for (let score of this.scorePoints) {
      this.wordsByScore[score].length = 0;
    }
  }

  selectWord(word: string) {
    if (this.resultsMode) {
      if (this.selectedWord !== word) {
        this.selectedWord = word;
      } else {
        this.selectedWord = '';
      }
      this.wordSelectEmitter.next(this.selectedWord);
    }
  }

  private addWordDetails(score: number, wordDetails: WordDetails) {
    const scoreWordList = this.wordsByScore[score];
      let i;
      for (i = 0; i < scoreWordList.length; i++) {
        if (wordDetails.word < scoreWordList[i].word) {
          break;
        }
      }
      scoreWordList.splice(i, 0, wordDetails);
  }

  private includeWord(wordDetails: WordDetails): boolean {
    if (this.wordFilter === 'all') {
      return true;
    }
    if (this.wordFilter === 'guessed' && wordDetails.playerIds.length) {
      return true;
    }
    if (this.wordFilter === 'missed' && !wordDetails.playerIds.length) {
      return true;
    }
    if (wordDetails.playerIds.indexOf(this.wordFilter) >= 0) {
      return true;
    }
    return false;
  }
}
