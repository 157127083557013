@switch (gameState) {
    @case (GameState.Title) {
        <app-title-screen></app-title-screen>
    }
    @case (GameState.Play) {
        <app-play-screen></app-play-screen>
    }
    @case (GameState.Results) {
        <app-results-screen></app-results-screen>
    }
    @default {
        Unexpected GameState encountered!
    }
}
<app-alert></app-alert>
